<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Election Information List</h4>
          <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['ar/election-information/create'])"
                       :to="'/election-information/create/'+lang"
                       class="btn btn-info btn-wd" wide>
            Add New
            <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
          </router-link>
        </div>
        <!--    filters    -->
        <div class="row col-12 mb-2">
          <div class="col-md-4 col-sm-4">
            <fg-select
              size="large"
              filterable
              clearable
              placeholder="Select Option"
              :input-classes="'select-default'"
              :list="isFeaturedOptions"
              :listItemLabel="'label'"
              :listItemValue="'value'"
              v-model="filters.is_featured">
            </fg-select>
          </div>
          <div class="col-md-2 col-sm-4">
            <l-button class="mr-lg-1" @click="applyFilter()" type="info">Filter Result</l-button>
          </div>
        </div>
        <div>
          <general-data-table
            ref="table"
            :lang="lang"
            :key="componentKey"
            @reorder="reorder($event)"
            :row-key="'id'"
            :api-url="'election-information/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :prop="column.value"
                             :sortable="column.sortable"
                             :label="column.label">
            </el-table-column>

            <el-table-column
              :min-width="120"
              fixed="right"
              align="center"
              label="Active">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/haveOneOfPermissions'](['ar/election-information/change-active-status']) ||
                                $store.getters['auth/checkAccessOwner']('restricted_own/ar/election-information/change-active-status', props.row.creator_id)"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>


              </template>
            </el-table-column>
            <el-table-column
              :min-width="120"
              fixed="right"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <template>
                  <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['ar/election-information/update']) ||
                                     $store.getters['auth/checkAccessOwner']('restricted_own/ar/election-information/update', scope.row.creator_id)"
                               v-tooltip.top-center="'Edit'"
                               class="btn-warning btn-simple btn-link"
                               :to="'/election-information/edit/'+scope.row.id+'/'+lang">
                    <i class="fa fa-edit"></i>
                  </router-link>
                  <router-link  v-if="$store.getters['auth/haveOneOfPermissions'](['election_information_ar_logs_view'])"
                                v-tooltip.top-center="'Log'"
                                class="btn-info btn-simple btn-link"
                                :to="'/logs/election_information_' + lang+'_'+scope.row.id"><i class="fa fa-eye"></i></router-link>

                  <a v-if="$store.getters['auth/haveOneOfPermissions'](['ar/election-information/delete']) ||
                           $store.getters['auth/checkAccessOwner']('restricted_own/ar/election-information/delete', scope.row.creator_id)"
                     v-tooltip.top-center="'Delete'"
                     class="btn-danger btn-simple btn-link"
                     @click="openDeleteModal(scope.row.id)">
                    <i class="fa fa-times"></i></a>
                </template>


              </template>
            </el-table-column>
          </general-data-table>
        </div>
      </card>
      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this Election Information?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
    </div>
  </div>
</template>

<script>
import {DeleteModal as DeleteModal, Switch as LSwitch} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import {TableColumn} from "element-ui";
import FgSelect from "@/components/Inputs/formGroupSelect";


export default {
  components: {
    GeneralDataTable,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    LSwitch,
    FgSelect,

  },

  data() {
    return {
      tableColumns: [
        {label: 'Election Information Title', value: 'name', minWidth: '200', align: 'center'},
        {label: 'Election Information URL', value: 'url', minWidth: '200', align: 'center'},
      ],
      isFeaturedOptions :[
        {
          label:'All',
          value:0
        },
        {
          label:'Featured',
          value:1
        }
      ],
      filters: {
        is_featured: 0
      },

      deleteModalVisibility: false,
      toDeleteId: undefined,
      lang: 'ar',
      componentKey: 0,
    }
  },
  created(){
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1)
  },
  methods: {
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active,
        'lang': this.lang,
      }
      this.axios.post("election-information/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: "Election Information updated successfully",
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {
        console.log(error)
      })
    },
    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },

    async confirmDeleteModal() {
      try {
        let data = {
          'id': this.toDeleteId,
          'lang': this.lang,
        }
        await this.axios.delete("election-information/delete", {headers: {}, data});
        this.$refs.table.getData("updateData");
        this.$notify({
          message: "Election Information deleted successfully",
          timeout: 1000,
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      } finally {
        this.closeDeleteModal();
        this.toDeleteId = undefined;
      }
    },
    applyFilter() {
      this.$refs.table.filters = {...this.filters};
      if (this.filters.is_featured === 1) {
        this.$refs.table.createDragAndDropSorting();
      } else {
        this.$refs.table.filters = null;
        this.$refs.table.destroyDragAndDropSorting();
      }
      this.$refs.table.getData("updateData");
    },
    async reorder(data) {
      try {
        let body = {};
        let orderData = [];
        data.forEach((row, i) => {
          orderData.push({"id": row.id, "sort_order": i + 1})
        });
        body = {
          lang: this.lang,
          data: orderData,
        }

        await this.axios.post("election-information/re-order", body);
        this.componentKey ++;
        this.$notify({
          message: "order updated successfully",
          timeout: 1000,
          type: 'success'
        });

      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      }
    },

  },
}
</script>

